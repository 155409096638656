.profile-container {
  display: grid;
  width: 200px;
  background-color: var(--main-background);
  border-radius: 12px;
  color: white;
  padding-bottom: 10px;
  height: min(calc(100vh - 150px), 450px);
  overflow-y: scroll;
  justify-content: center;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-top: 27px;
}
.profile-container::-webkit-scrollbar {
  display: none;
}
.profile-picture {
  width: 100px;
  height: 100px;
  position: relative;
  left: 28px;
}

.profile-link {
  text-decoration: none;
  color: white;
  margin-bottom: 30px;
}

.profile-link:hover {
  text-decoration: underline;
}

.playlist-name-input {
  width: 150px;
  height: 20px;
  border-radius: 7px;
  background-color: white;
  color: var(--header-background-spotify);
  border: 2px solid var(--main-background);
}

.playlist-name-input:focus {
  outline: none;
  border: 2px solid var(--header-background-spotify);
}

.playlist-name-submit {
  background-color: rgb(138, 162, 223);
  color: var(--header-background-spotify);
  width: 120px;
  height: 40px;
  margin-top: 5px;
  border-radius: 6px;
  border: none;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
}

.playlist-name-submit:hover {
  cursor: pointer;
}

.playlist-name-submit:active {
  transform: translateY(1px);
}

.playlist-name-tooltip {
  position: absolute;
  visibility: hidden;
  margin-left: 5px;
  width: 120px;
  height: 100px;
  background-color: var(--main-background);
  height: fit-content;
  z-index: 9;
  border-radius: 10px;
  padding: 5px;
  margin-top: 5px;
  margin-left: 15px;
  box-shadow: 0px 0px 3px 1px var(--header-background-spotify);
}

.submit-button:hover .playlist-name-tooltip {
  visibility: visible;
}
